import React from 'react'

import { useLocation } from '@remix-run/react'

type Props = {
  children: JSX.Element
  open?: boolean
  summary: string
}

type ReactNode = React.ReactElement | string | null | undefined

const findMatchingHref = (element: ReactNode | ReactNode[], currentPath: string): boolean => {
  // Handle null/undefined/string cases
  if (!element || typeof element === 'string') {
    return false
  }

  // If element is an array, check each item
  if (Array.isArray(element)) {
    return element.some((child) => findMatchingHref(child, currentPath))
  }

  // Check current element's href
  if ('props' in element && element.props?.href === currentPath) {
    return true
  }

  // Get children from props
  const elementChildren = 'props' in element ? element.props?.children : null

  // If children is an array, check each child
  if (Array.isArray(elementChildren)) {
    return elementChildren.some((child) => findMatchingHref(child, currentPath))
  }

  // If children is a single object, check it
  if (elementChildren && typeof elementChildren === 'object') {
    return findMatchingHref(elementChildren, currentPath)
  }

  return false
}

export default function Details({ children, summary, open = false }: Props) {
  const location = useLocation()
  const currentPath = location.pathname

  const shouldBeOpen = React.useMemo(() => {
    return findMatchingHref(children, currentPath) || Boolean(open)
  }, [children, currentPath, open])

  return (
    <details open={shouldBeOpen}>
      <summary className='details-summary'>{summary}</summary>
      <div className='details-content'>{children}</div>
    </details>
  )
}

Details.scheme = {
  render: 'Details',
  attributes: {
    open: {
      type: Boolean
    },
    summary: {
      type: String
    }
  }
}
